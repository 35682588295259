export enum Language {
	Swedish = "sv",
	English = "en",
	Latvia = "lv",
	Estonia = "et",
	Danish = "da",
	Norway = "nb",
	Finnish = "fi",
	German = "de",
    Iceland = "is",
    Lithuania = "lt",
}