import React from "react";

const LithuaniaFlag = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75px"
            height="46px"
            viewBox="0 0 211 130"
        >
            <path fill="#c1272d" d="M0 0h211v130H0z" />
            <path fill="#006a44" d="M0 0h211v86.666H0z" />
            <path fill="#fdb913" d="M0 0h211v43.333H0z" />
        </svg>
    );
};

export { LithuaniaFlag };
