import { Language } from '../Models/Enums'
import { swedenSupport,norweigianSupport, finlandSupport } from './Constants'

export const GetSupportPage = (language: Language) =>
{
	switch (language)
	{
		case Language.Swedish:
			return swedenSupport;
		case Language.Finnish:
			return finlandSupport;
		case Language.Norway:
			return norweigianSupport;
		default:
			return swedenSupport;
	}
}