import { useTranslation } from 'react-i18next'
import './Disclaimer.scss'
import { Link } from 'react-router-dom'


const Disclaimer = () => {
	const { t } = useTranslation();
	return (
		<div className='content'>
			<h1>{t("disclaimerSubjectHigherCase")}</h1>
			<br />
			<div>
				<p className='paragraph'>
					{t("disclaimer")}
				</p>
			</div>
			<div>
				<Link className='linkBack' to={'/'}>{t("backToInstall")}</Link>
			</div>
		</div>
	)
}

export default Disclaimer