import { Language } from "../Models/Enums";

export const GetInstructions = (language: Language) => {
	switch (language) {
		case Language.English:
			return ".pdf";
		case Language.Swedish:
			return ".sv-SE.pdf";
		case Language.Finnish:
			return ".fi.pdf";
		case Language.Estonia:
			return ".et.pdf";
		case Language.Danish:
			return ".da.pdf";
		case Language.Norway:
			return ".nb.pdf";
		case Language.Iceland:
			return ".is-IS.pdf";
		case Language.German:
			return ".de.pdf";
		case Language.Latvia:
			return ".lv-LV.pdf"
		default:
			return ".pdf";
	}
}