import React from 'react'
import './InfoContainer.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { GetSupportPage } from '../../Utilities/GetSupportPage'
import { Language } from '../../Models/Enums'

const DisclaimerContainer: React.FunctionComponent = () => {
	const { t, i18n } = useTranslation();

	return (
		<div className='container'>
			<div>
				<h2 className='text'>{t("disclaimerInfoText")}
					<Link to={'/disclaimer'} className='linkText'>{t("disclaimerSubject")}</Link>.</h2>
			</div>
			<br />
			<div>
				<h2 className='text'>{t("Contact")}<Link to={GetSupportPage(i18n.language as Language)} className='linkText'>{t("CabSupport")}</Link> {t("problems")}.</h2>
			</div>
		</div>
	)
}

export default DisclaimerContainer