import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEnglish from "./Translation/English/translation.json";
import translationSwedish from "./Translation/Swedish/translation.json";
import translationLatvia from "./Translation/Latvia/translation.json";
import translationFinland from "./Translation/Finnish/translation.json";
import translationGerman from "./Translation/German/translation.json";
import translationEstonia from "./Translation/Estonian/translation.json";
import translationIceland from "./Translation/Iceland/translation.json";
import translationDanish from "./Translation/Danish/translation.json";
import translationNorway from "./Translation/Norwegian/translation.json";
import translationLithuania from "./Translation/Lithuanian/translation.json";

var resources = {
    en: {
        translation: translationEnglish,
    },
    sv: {
        translation: translationSwedish,
    },
    lv: {
        translation: translationLatvia,
    },
    fi: {
        translation: translationFinland,
    },
    de: {
        translation: translationGerman,
    },
    et: {
        translation: translationEstonia,
    },
    da: {
        translation: translationDanish,
    },
    is: {
        translation: translationIceland,
    },
    nb: {
        translation: translationNorway,
    },
    lt: {
        translation: translationLithuania,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    interpolation: { escapeValue: false },
});

export default i18n;
