import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Logo from '../Logo/Logo';
import Disclaimer from '../../Pages/Disclaimer/Disclaimer';
import './BaseContainer.scss'
import CabasDownloadDefault from '../../Pages/CABAS/CabasDownloadPage';

const BaseContainer = () => {
	return (
		<div className="baseContainer">
			<center>
				<Logo />
			</center>
			<Router>
				<Routes>
					<Route path="/" element={<CabasDownloadDefault />} />
					<Route path="/disclaimer" element={<Disclaimer />} />
				</Routes>
			</Router>
		</div>
	)
}

export default BaseContainer