import { create } from 'zustand'

type VersionState = {
	Version: string,
	setVersion: (version: string) => void
}

export const versionStore = create<VersionState>((set) => ({
	Version: "",
	setVersion: (Version) => set(() => ({ Version }))
}))