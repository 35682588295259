import React from 'react'

interface IProps {
	className: string,
	link: string,
	buttonText: string,
}

const Button: React.FunctionComponent<IProps> = (props: IProps) => {

	const openLink = (link: string) => {
		window.open(props.link, "_blank");
		console.log("Link: " + link.toString() + " was opened");
	}
	return (
		<>
			<button type='button' className={props.className} onClick={() => openLink(props.link)}>{props.buttonText}</button>
		</>
	)
}

export default Button