import FlagContainer from '../../Components/FlagContainer/FlagContainer';
import ContentContainer from '../../Components/ContentContainer/ContentContainer';
import DisclaimerContainer from '../../Components/InfoContainer/InfoContainer';
import { useTranslation } from 'react-i18next'
import '../DefaultLayoutCss.scss'

const CabasDownloadPage = () => {
	const { t } = useTranslation();
	return (
		<div>
			<div className='top'>
				<h1>{t("CABHeadLine")}</h1>
			</div>
			<div className='flagContainer'>
				<FlagContainer />
			</div>
			<ContentContainer />
			<DisclaimerContainer />
		</div >
	)

}

export default CabasDownloadPage;