import CABLogo from '../../Images/CABLogo.svg';
import './Logo.scss'

const Logo = () => {
	return (
		<>
			<img className='logo' alt='CABLogo' src={CABLogo}></img>
		</>
	)
}

export default Logo