
//Download links to nessecary software
export const downloadDotnetFramework = "https://go.microsoft.com/fwlink/?LinkId=2085155";
export const downloadWebView2 = "https://go.microsoft.com/fwlink/p/?LinkId=2124703";

//Texts
export const dotnetDownloadText = "Microsoft .NET Framework 4.8";
export const webView2DownloadText = "Microsoft Edge WebView2 Runtime";

//filePaths
export const downloadInstallationGuidePath = "../../docs/cabinstall"

//EnvironmentVariables
export const downloadCabasLink = process.env.REACT_APP_DOWNLOAD_CLIENT as string;
export const downloadInstallationGuide = process.env.REACT_APP_PDF_DOWNLOAD_URL as string;

//Links to supportPages
export const swedenSupport = "https://cabgroup.se/se/fordon/verktygsmenyn/support.html"
export const dannishSupport = ""
export const deutschSupport = "https://www.cab.se/de/deutsch/cab-group/fahrzeug/support.html"
export const norweigianSupport = "https://cabgroup.se/no/kjoretoy/verktoysmeny/support.html"
export const latvianSupport = ""
export const finlandSupport = "https://cabgroup.se/fi/ajoneuvot/valikko/support.html"
export const estlandSupport = ""
export const englishSupport = ""