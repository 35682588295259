const IcelandFlag = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75px"
            height="46px"
            viewBox="0 0 211 130"
        >
            <path d="M0 0H211V130H0Z" fill="#02529C" />
            <path d="M0 65H211" strokeWidth="26" stroke="#FFF" />
            <path d="M65 0V130" strokeWidth="26" stroke="#FFF" />
            <path d="M0 65H211" strokeWidth="13" stroke="#DC1E35" />
            <path d="M65 0V130" strokeWidth="13" stroke="#DC1E35" />
        </svg>
    );
};

export { IcelandFlag };
