import englishLogo from "../../Images/english.jpg";
import swedishLogo from "../../Images/swedish.jpg";
import finnishLogo from "../../Images/finnish.jpg";
import latviaLogo from "../../Images/latvian.jpg";
import estonianLogo from "../../Images/estonian.jpg";
import norwegianLogo from "../../Images/norwegian.jpg";
import germanLogo from "../../Images/german.jpg";
import swedishFinnishLogo from "../../Images/swedish_finland.jpg";
import { useTranslation } from "react-i18next";
import { Language } from "../../Models/Enums";
import "./FlagContainer.scss";
import { LithuaniaFlag } from "../FlagSvgComponents/LithuaniaFlag";
import { IcelandFlag } from "../FlagSvgComponents/IcelandFlag";

const FlagContainer = () => {
    const { i18n } = useTranslation();
    return (
        <div className="flagContainer">
            <div className="row">
                <img
                    className="image"
                    src={englishLogo}
                    alt="English"
                    width="75"
                    title="English"
                    onClick={() => i18n.changeLanguage(Language.English)}
                />
                <img
                    className="image"
                    src={swedishLogo}
                    alt="Swedish"
                    width="75"
                    title="Swedish"
                    onClick={() => i18n.changeLanguage(Language.Swedish)}
                />
                <img
                    className="image"
                    src={finnishLogo}
                    alt="Finnish"
                    width="75"
                    title="Finnish"
                    onClick={() => i18n.changeLanguage(Language.Finnish)}
                />
                <img
                    className="image"
                    src={latviaLogo}
                    alt="Latvia"
                    width="75"
                    title="Latvia"
                    onClick={() => i18n.changeLanguage(Language.Latvia)}
                />
                <img
                    className="image"
                    src={swedishFinnishLogo}
                    alt="SwedishFinnish"
                    width="75"
                    title="SwedishFinnish"
                    onClick={() => i18n.changeLanguage(Language.Swedish)}
                />
            </div>
            <div className="row">
                <img
                    className="image"
                    src={estonianLogo}
                    alt="Estonian"
                    width="75"
                    title="Estonian"
                    onClick={() => i18n.changeLanguage(Language.Estonia)}
                />
                <img
                    className="image"
                    src={norwegianLogo}
                    alt="Norwegian"
                    width="75"
                    title="Norwegian"
                    onClick={() => i18n.changeLanguage(Language.Norway)}
                />
                <img
                    className="image"
                    src={germanLogo}
                    alt="German"
                    width="75"
                    title="German"
                    onClick={() => i18n.changeLanguage(Language.German)}
                />
                <div
                    className="imageSvg"
                    onClick={() => i18n.changeLanguage(Language.Lithuania)}
                >
                    <LithuaniaFlag />
                </div>
                <div
                    className="imageSvg"
                    onClick={() => i18n.changeLanguage(Language.Iceland)}
                >
                    <IcelandFlag />
                </div>
            </div>
        </div>
    );
};

export default FlagContainer;
