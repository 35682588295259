import './ContentContainer.scss'
import { useTranslation } from 'react-i18next'
import Button from '../Button/Button';
import { useEffect } from 'react'
import { versionStore } from '../../Store/Version/store';
import { GetVersionTag } from '../../Store/Version/effects';
import { GetInstructions } from '../../Utilities/GetInstructions';
import {
	downloadDotnetFramework,
	downloadWebView2,
	dotnetDownloadText,
	webView2DownloadText,
	downloadCabasLink,
	downloadInstallationGuidePath
} from '../../Utilities/Constants';
import { Language } from '../../Models/Enums';

const ContentContainer = () => {
	const versionState = versionStore((state) => state.Version);
	const setVersionState = versionStore((state) => state.setVersion);

	useEffect(() => {
		const GetVersion = async () => {
			try {
				const version = await GetVersionTag();
				setVersionState(version);
			} catch (error)
			{
				console.error('Error fetching version:', error);
			}
			
		}
		GetVersion();
		// eslint-disable-next-line
	}, [])

	const { t, i18n } = useTranslation();

	return (
		<div className='contentContainer'>
			<p className='headline'>{t("prerequisitesInfo")}:</p>
			<div className='buttonBase'>
				<Button link={downloadDotnetFramework} buttonText={dotnetDownloadText} className={'button'} />
				<br />
				<Button link={downloadWebView2} buttonText={webView2DownloadText} className={'button'} />
			</div>
			<p className='headlineInstallation'>{t("prerequisitesMeetinstallation")}:</p>
			<div className='buttonBase'>
				<Button link={downloadCabasLink} buttonText={t("install") + " " + versionState} className={'downloadbutton'} />
			</div>
			<div className='link'>
				<Button link={downloadInstallationGuidePath + GetInstructions(i18n.language as Language)} className={'linkButton'} buttonText={t("installationsGuide")}></Button>
			</div>
		</div>
	)
}

export default ContentContainer